import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = () => {
  const [autoplay, setAutoplay] = useState(true);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: autoplay,
    // autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
  };

  const handleSliderHover = (isHovering) => {
    setAutoplay(!isHovering);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Slider
          ref={sliderRef}
          {...settings}
          onMouseEnter={() => handleSliderHover(true)}
          onMouseLeave={() => handleSliderHover(false)}
        >
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                SurveyNav tool with features like Power BI dashboard, Jocker
                analysis and Adverse event identification is going to be live in
                Jul'24
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                AssetNav scoring framework is set for an upgrade by Sep’24 to
                enhance user experience, focusing on efficient data structuring.
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                GenAI adoption in ClarityNav and enhancements are in progress
                Dec'24.
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                New disease obesity and chronic heart failure added in AssetNav.
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                AssetNav saved &sim; 200 hours of manual effort on a single
                project.
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
              AI solution to optimize client coverage, gather medical insights at key conferences, ensuring strategic analysis by Dec '24.
              </p>
            </div>
          </div>
          <div className="card-slider-box">
            <div className="card-slider-sp">
              <p className="card-slider-text">
                Specialty team has conducted, first client project on IndicationNav
                (Recodarti)
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default CardSlider;
